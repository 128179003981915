<template>
    <div class="notifications" :class="{
        'has-notifications' : notifications.length > 0
    }">
        <notification 
            v-for="(notification, index) in notifications" 
            :key="notification.text + index"
            :notification="notification" 
            @close-notification="removeNotification" 
        ></notification>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Notification from './Notification';
export default {
    components: {
        Notification
    },
    computed: {
        ...mapGetters({
            notifications: 'notifications/list',
        }),
    },
    methods: {
        removeNotification: function (notification) {
            this.$store.commit('notifications/REMOVE_NOTIFICATION', notification);
        },
    },
}
</script>