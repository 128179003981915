<template>
  <div
    class="collapse navbar-collapse w-auto h-auto h-100 overflow-x-visible"
    id="sidenav-collapse-main"
    style="max-height:100%;"
  >
    <div class="px-4 py-2 w-100">
      <h6 class="my-2">Date range:</h6>
      <DatePickerComponent @date-selected="updateDate" :dates="$store.state.activeReportingDates" :min-date="minimumDate" :key="$store.state.activeReportingDates"/>
      <TimeZonePicker @timezone-selected="updateTZ"></TimeZonePicker>
    </div>

    <ul class="navbar-nav overflow-navbar-vertical" style="max-height: 42vh;overflow-y: auto;" v-if="$store.state.activeReporting && $store.state.activeReporting.creatives" :key="$store.state.activeReporting">
      <li class="nav-item">
        <sidenav-item
          v-if="this.$route.params.shareToken"
          :url="{ name: 'Share campaign reporting', params: {shareToken: $route.params.shareToken || ''} }"
          :class="getRoute() === 'dashboard-default' ? 'active' : ''"
          :navText="'Campaign summary'"
        >
        </sidenav-item>
        <sidenav-item
          v-else
          :url="{ name: 'Campaign reporting', params: {campaignID: $store.state.activeReporting.id || ''} }"
          :class="getRoute() === 'dashboard-default' ? 'active' : ''"
          :navText="'Campaign summary'"
        >
        </sidenav-item>
      </li>
      <li class="mt-3 nav-item nav-category">
        <h6
          class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
          :class="'ms-2'"
        >
          Creatives
        </h6>
      </li>
      <li v-for="creative in $store.state.activeReporting.creatives.filter((creative) => creative.liveCreatives && creative.liveCreatives.length > 0)" :key="creative.id" class="nav-item">
        <sidenav-item
          v-if="this.$route.params.shareToken"
          :url="{ name: 'Share creative reporting', params: {shareToken: $route.params.shareToken || '', creativeID: creative.id || ''} }"
          :class="getRoute() === creative.id ? 'active' : ''"
          :navText="creative.name"
        >
        </sidenav-item>
        <sidenav-item
          v-else
          :url="{ name: 'Creative reporting', params: {campaignID: $store.state.activeReporting.id || '', creativeID: creative.id || ''} }"
          :class="getRoute() === creative.id ? 'active' : ''"
          :navText="creative.name"
        >
        </sidenav-item>
      </li>
      
    </ul>

    <div class="px-4 py-2 overflow-y-auto overflow-navbar-vertical w-100" style="min-height: 25vh;">


      <h6 class="my-2">Export reporting</h6>
      <div class="col-md-12">
          <label for="Report-download" class="form-control-label"
          >Report download</label
          >
          <UIDropdown name="Report-download" id="Report-download" :modelValue="dlReportFormat" @input="updateReportType" :values="dlReportFormats" />
      </div>
      <div class="col-md-12">
        <UIButton color="info" size="sm" @click="reportDownload" class="ms-auto">Download</UIButton>
      </div>
      <div v-if="!this.$route.params.shareToken">
        <div class="col-md-12">
            <label for="Share-Report" class="form-control-label"
            >Share link</label
            >
            <UIInput type="url" :modelValue="$store.state.activeReporting.reportURL" name="Share-Report" placeholder="" :readonly="true" :key="$store.state.activeReporting.reportURL"/>
        </div>
        <div class="col-md-12">
          <UIButton color="success" size="sm" class="ms-auto" @click="getShareURL">Generate URL</UIButton>
        </div>
        <div class="col-md-12 mt-3">
          <UIButton color="primary" size="sm" class="ms-auto" @click="openScheduleModal">Schedule reporting</UIButton>
        </div>
      </div>
      <!-- <h6 class="my-2">Scheduled reporting:</h6>
      <div class="col-md-12">
        <label for="Email-schedule" class="form-control-label"
          >Emails:</label
        >
        <UIInput type="text" :value="$store.state.activeReporting.scheduled_emails" name="Email-schedule" placeholder="Insert emails for scheduled reports" />
      </div>
      <div class="col-md-12">
        <label for="Report-type" class="form-control-label"
          >Report type</label
        >
        <UIDropdown name="Report-type" id="Report-type" :modelValue="reportTypes[0]" :values="reportTypes" />
      </div>
      <div class="col-md-12">
        <label for="Report-frequency" class="form-control-label"
          >Report frequency</label
        >
        <UIDropdown name="Report-frequency" id="Report-frequency" :modelValue="reportFrequencies[0]" :values="reportFrequencies" />
      </div>
      <div class="col-md-12">
        <label for="Report-format" class="form-control-label"
          >Report format</label
        >
        <UIDropdown name="Report-format" id="Report-format" :modelValue="reportFormats[0]" :values="reportFormats" />
      </div> -->
    </div>

  </div>
</template>
<script>
import SidenavItem from "@/components/Sidenav/SidenavItem.vue";
import UIInput from "@/components/UIInput.vue";
import UIDropdown from "@/components/UIDropdown.vue";
import UIButton from "@/components/UIButton.vue";
import DatePickerComponent from '@/components/reporting/DatePicker.vue';
import TimeZonePicker from "@/components/reporting/TimeZonePicker.vue";

function downloadURI(uri, name) {
  const link = document.createElement("a");
  link.download = name;
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export default {
  name: "ReportingSidenavList",
  props: {
    cardBg: String
  },
  data() {
    return {
      title: "Aderize Admin Dashboard",
      isActive: "active",
      showAdminDrop: false,
      campaign:this.$route.params.campaignID ? this.$store.getters['campaigns/getByID'](this.$route.params.campaignID) : this.$store.state.activeReporting,
      reportTypes: [
        "Summary",
        "Full"
      ],
      reportFrequencies: [
        "Daily",
        "Weekly",
        "Monthly",
        "On campaign end"
      ],
      dlReportFormats: [
        "PDF",
        "CSV",
        "Excel",
        //"Excel with Pivot"
      ],
      reportFormats: [
        "CSV",
        "Excel",
        "Excel with Pivot"
      ],
      dlReportFormat: 'PDF'
    };
  },
  computed: {
    useDates() {
      let output = [];
      if (!this.$store.state.activeReportingDates) {
        if (this.$store.state.activeReporting && this.$store.state.activeReporting.data) {
          const campaign = this.$store.state.activeReporting;
          output = [
              new Date(campaign.data.start.date),
              campaign.data.end && new Date(campaign.data.end.date) || new Date()
          ];

        }
      }
      return output || this.$store.state.activeReportingDates;
    },
    minimumDate() {
      //const useCampaign = this.campaign || this.$store.state.activeReporting;
      /* if(useCampaign && useCampaign.data && useCampaign.data.start && useCampaign.data.start.date) {
        return new Date(useCampaign.data.start.date);
      } */
      return null;
    }
  },
  components: {
    SidenavItem,
    DatePickerComponent,
    UIInput,
    UIDropdown,
    UIButton,
    TimeZonePicker
  },
  methods: {
    openScheduleModal() {
      this.$store.state.showScheduleModal = true;
    },
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
    getShareURL() {
      const useCampaign = this.campaign || this.$store.state.activeReporting;
      const that = this;
      this.$store.state.campaigns.loading = true;
      this.$store.getters['campaigns/createNewReportToken']({campaignID: useCampaign.id}).then((response) => {
        this.$store.state.campaigns.loading = false;
        if (response.data && response.data.url) {
          let useURL = response.data.url;
          if (this.$route.query.start) {
            useURL += (useURL.indexOf('?') >= 0 ? '&' : '?') + 'start=' + this.$route.query.start
          }
          if (this.$route.query.tz) {
            useURL += (useURL.indexOf('?') >= 0 ? '&' : '?') + 'tz=' + this.$route.query.start
          }
          this.$store.state.activeReporting.reportURL = useURL;
        }
      }).catch((error) => {
            that.$store.state.campaigns.loading = false;

            console.error(error);
            that.$store
                .commit('notifications/ADD_NOTIFICATION', {
                    title: 'Error!',
                    text: 'Couldn\'t receive reporting! Please try again.',
                    type: 'danger',
                    timeout: true,
                    delay: 10000
                });
        })
    },
    updateDate(dates) {
      if (!dates && this.$store.state.activeReportingDates) dates = this.$store.state.activeReportingDates;
      else {
        if (!dates) return;
        if (!this.$store.state.activeReportingDates) this.$store.state.activeReportingDates = [];
        this.$store.state.activeReportingDates[0] = dates[0].getTime && dates[0] || new Date(dates[0]);
        this.$store.state.activeReportingDates[1] = dates[1].getTime && dates[1] || new Date(dates[1]);
      }
    },
    updateTZ(tz) {
      if (this.$store.state.activeReportingTimezone && this.$store.state.activeReportingTimezone.offset) {
        Object.assign(this.$store.state.activeReportingTimezone,tz);
      } else {
        this.$store.state.activeReportingTimezone = tz;

      }
    },
    updateReportType(newType) {
      this.dlReportFormat = newType;
    },
    reportDownload() {
      if (this.dlReportFormat === 'PDF') {
        window.print(); 
      } else {
        let reportType = 'CSV';
        switch(this.dlReportFormat) {
          case 'CSV':
            reportType = 'csv';
            break;
          case 'Excel':
            reportType = 'xlsx';
            break;
          case 'Excel with Pivot':
            reportType = 'xlsx-pivot';
            break;
        }
        const that = this;
        this.$store.state.campaigns.loading = true;
        const useCampaign = this.campaign || this.$store.state.activeReporting;
        let tzOffset = this.$store.state.activeReportingTimezone && this.$store.state.activeReportingTimezone.offset || '+00:00';

        if (!this.$store.state.activeReportingDates || this.$store.state.activeReportingDates[0]) {
          if (!this.$store.state.activeReportingDates) this.$store.state.activeReportingDates = [];
          if (this.$route.query.start) {
            this.$store.state.activeReportingDates[0] = new Date(this.$route.query.start*1000)
          }
          if (this.$route.query.end) {
            this.$store.state.activeReportingDates[1] = new Date(this.$route.query.end*1000)
          }

        }

        const endPoint = this.$route.params.shareToken ? 'campaigns/getSharedDateReport' : 'campaigns/getCampaignDateReport'
        const useID = this.$route.params.shareToken ? this.$route.params.shareToken : useCampaign.id;
        this.$store.getters[endPoint](useID,this.$store.state.activeReportingDates[0],this.$store.state.activeReportingDates[1], tzOffset, reportType).then((report) => {
            const API_URL = process.env.VUE_APP_API_URL
            if(report.file) {
              const fileUrl = API_URL + '/uploads/' + report.file;
              const filename = fileUrl.split('/').pop();
              downloadURI(fileUrl,filename);
            }
            this.$store.state.campaigns.loading = false;

        }).catch((error) => {
            that.$store.state.campaigns.loading = false;

            console.error(error);
            that.$store
                .commit('notifications/ADD_NOTIFICATION', {
                    title: 'Error!',
                    text: 'Couldn\'t receive reporting! Please try again.',
                    type: 'danger',
                    timeout: true,
                    delay: 10000
                });
        })
      }
    }
  },
  mounted() {
    const useCampaign = this.campaign || this.$store.state.activeReporting;
    if (this.$route.query.start) {
      this.$store.state.activeReportingDates[0] = new Date(this.$route.query.start*1000)
    }
    if (this.$route.query.end) {
      this.$store.state.activeReportingDates[1] = new Date(this.$route.query.end*1000)
    }
    if (this.$route.query.tz) {
      if (!this.$store.state.activeReportingTimezone || !(this.$store.state.activeReportingTimezone instanceof Object)) this.$store.state.activeReportingTimezone = {};
      this.$store.state.activeReportingTimezone.offset = this.$route.query.tz;
    }
    if (!this.$store.state.activeReportingDates) {
        if (useCampaign && useCampaign.data) {
          const campaign = useCampaign;
          this.$store.state.activeReportingDates = [
              new Date(campaign.data.start.date),
              campaign.data.end && new Date(campaign.data.end.date) || new Date()
          ];

        }
      }
    document.querySelector('#app').classList.add('g-sidenav-secondary')
  },
  unmounted() {
    document.querySelector('#app').classList.remove('g-sidenav-secondary')
  }
};
</script>
