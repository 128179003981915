<template>
  <div class="form-group">
    <div class="dropdown">
      <a
        class="cursor-pointer input-group"
        :name="name" 
        :id="id"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <span class="form-control"> 
          {{ selectedVal && selectedVal.name || selectedVal || 'None' }} 
        </span>
        <span class="input-group-text">
          <i class="fa fa-caret-down text-secondary" aria-hidden="true"></i>
        </span>
        
      </a>
      <ul
        class="dropdown-menu px-2 py-3"
        :aria-labelledby="name"
        style
      >
        <li v-for="(part) in values" :key="part">
          <a class="dropdown-item border-radius-md" @click="elementChanged(part)">{{part.name||part}}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "UIDropdown",
  props: {
    name: String,
    id: String,
    values: Array,
    modelValue: [String, Object]
  },
  data() {
    return {
      selectedVal: this.modelValue
    }
  },
  emits: ['update:modelValue', 'input'],
  methods: {
    elementChanged(ev) {
      this.selectedVal = ev;
      this.$emit('update:modelValue', this.selectedVal);
      this.$emit('input', this.selectedVal);
    }
  }
};
</script>
