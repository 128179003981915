<template>
    <div class="container top-0 position-sticky z-index-sticky">
      <div class="row">
        <div class="col-12">
          <navbar
            isBlur="blur  border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"
            v-bind:darkMode="true"
            isBtn="bg-gradient-success"
          />
        </div>
      </div>
    </div>
    <main class="mt-0 main-content">
      <section>
        <div class="page-header min-vh-100">
          <div class="container">
            <div class="row">
              <div class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0">
                <div class="card card-plain">
                  <div class="pb-0 card-header text-start">
                    <h4 class="font-weight-bolder">Forgot password?</h4>
                    <p class="mb-0" v-if="forgotPasswordToken">Enter your email and new password to reset</p>
                    <p class="mb-0" v-else>Enter your email and we'll send you a link for resetting password</p>
                  </div>
                  <div class="card-body">
                    <div class="alert" v-if="alert">{{ alert }}</div>
                    <form @submit.prevent="forgotPassword" role="form">
                      <div class="mb-3">
                        <UIInput type="email" placeholder="Email" name="email" size="lg" v-model="email" />
                      </div>
                      <div v-if="forgotPasswordToken">
                        <div class="mb-3">
                            <UIInput type="password" placeholder="Password" name="password" size="lg" v-model="password" />
                        </div>
                        <div class="mb-3">
                            <UIInput type="password" placeholder="Confirm password" name="confirmPassword" size="lg" v-model="confirmPassword" />
                        </div>
                      </div>
  
                      <div class="text-center">
                        <UIButton
                          v-if="!forgotPasswordToken"
                          class="mt-4"
                          variant="gradient"
                          color="success"
                          fullWidth
                          size="lg"
                        >Send me password reset link</UIButton>
                        <UIButton
                          v-else
                          class="mt-4"
                          variant="gradient"
                          color="success"
                          fullWidth
                          size="lg"
                        >Change password</UIButton>
                      </div>
                    </form>
                  </div>
                  <div class="px-1 pt-0 text-center card-footer px-lg-2">
                    <p class="mx-auto mb-4 text-sm">
                      Don't have an account?
                      <a
                        href="javascript:;"
                        class="text-success text-gradient font-weight-bold"
                      >Sign up</a>
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="top-0 my-auto text-center col-6 d-lg-flex d-none h-100 pe-0 position-absolute end-0 justify-content-center flex-column"
              >
                <div
                  class="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden"
                  :style="{backgroundImage: 'url(' + require('@/assets/img/signin.jpg') + ')',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center right'}"
                >
                  <span class="mask bg-gradient-success opacity-6"></span>
                  <h4
                    class="mt-5 text-white font-weight-bolder position-relative"
                  >"Attention is the new currency"</h4>
                  <p
                    class="text-white position-relative"
                  >Let's focus people!.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </template>
  
  <script>
  import Navbar from "@/examples/PageLayout/Navbar.vue";
  import UIInput from "@/components/UIInput.vue";
  import UIButton from "@/components/UIButton.vue";
  const body = document.getElementsByTagName("body")[0];
  
  function getData(form) {
    const formData = new FormData(form);
  
    const output = Object.fromEntries(formData);
  
    return output;
  }
  
  export default {
    name: "forgot-password",
    components: {
      Navbar,
      UIInput,
      UIButton,
    },
    data() {
      return {
        email: "",
        password: "",
        confirmPassword: "",
        alert: "",
        alertSuccess: false,
        loading: false,
        forgotPasswordToken: this.$route.query.forgotToken
      };
    },
    created() {
      this.$store.state.hideConfigButton = true;
      this.$store.state.showNavbar = false;
      this.$store.state.showSidenav = false;
      this.$store.state.showFooter = false;
      body.classList.remove("bg-gray-100");
    },
    beforeUnmount() {
      this.$store.state.hideConfigButton = false;
      this.$store.state.showNavbar = true;
      this.$store.state.showSidenav = true;
      this.$store.state.showFooter = true;
      body.classList.add("bg-gray-100");
    },
    methods: {
      forgotPassword: function(e) {
        const that = this;
        that.alert = '';
        that.loading = true;
        const formData = getData(e.target);
        if (that.forgotPasswordToken) {
          that.$store.dispatch("auth/changePassword",{
            username: formData.email,
            password: formData.password,
            confirmPassword: formData.confirmPassword,
            forgotToken: that.forgotPasswordToken
          }).then((response) => {
            console.log("Got a good response",response)
            that.loading = false;
            that.$router.push('/signin');
          }).catch((err,err2) => {
            console.log("ERRROR",err, err2);
            let errorMessage = err;
            if (err && err.data) errorMessage = err.data.error.message || err.data.error;
            if (errorMessage instanceof Array) errorMessage = errorMessage.join(",\n");
            that.alert = errorMessage;
            that.loading = false;
            that.alertSuccess = false;
          })

        } else {
          that.$store.dispatch("auth/forgotPassword",{
            email: formData.email
          }).then((response) => {
            console.log("Got a good response",response)
            that.loading = false;
            that.alert = 'Check your email for next steps';
            that.alertSuccess = true;
          }).catch((err) => {
            console.log("ERRROR",err);
            let errorMessage = err;
            if (err && err.data) errorMessage = err.data.error.message || err.data.error;
            if (errorMessage instanceof Array) errorMessage = errorMessage.join(",\n");
            that.alert = errorMessage;
            that.loading = false;
            that.alertSuccess = false;
          })
        }
      }
    }
  };
  </script>
  