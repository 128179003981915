import { reactive } from 'vue';

const storeDefault = {
  list: [],
};

const store = reactive(storeDefault);

const getters = {
    list: store => store.list,
}

export const notifications = {
  namespaced: true,
  state: store,
  mounted() {

  },
  mutations: {
    ADD_NOTIFICATION(state, payload) {
        state.list.push(payload);
    },

    REMOVE_NOTIFICATION(state, payload) {
        state.list = state.list.filter(notification => notification.text !== payload.text);
    }
  },
  getters: getters

};
