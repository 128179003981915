import axios from 'axios';
import { setCorrectLocationParams } from '@/store/filemanager.module';
/* import router from "../router";
 */
const API_URL = process.env.VUE_APP_API_URL

class AuthService {
  login(data) {
    let hasError = [];
    if (!data.username || data.username.length < 6) {
        hasError.push("Fill in your email");
    }
    if (!data.password || data.password.length < 6) {
        hasError.push("Fill in your password at least 6 characters long");
    }
    if (hasError && hasError.length > 0) {
        return new Promise((resolve, reject) => {
          reject({
            data: {
                error: {
                status: 400,
                message: hasError,
                data: data
              }
            }
          });
        });
    }

    return axios
      .post(API_URL + '/login', {
        method: "password",
        username: data.username,
        password: data.password,
        remember: Boolean(data.remember)
      }, {withCredentials: true})
      .then(response => {
        if (response.data.status == 200 && response.data.user) {
          let userData = response.data.user;
          userData.expiry = response.data.expiry;
          userData.location = response.data.location || localStorage.getItem('location');
          userData.lastRequest = Date.now();
          userData.lastLogin = Date.now();
          localStorage.setItem('user', JSON.stringify(userData));
          if(response.data.location) localStorage.setItem('location', JSON.stringify(response.data.location));
          setCorrectLocationParams(userData.location);
        }
        return response.data.user;
      });
  }

  forgotPassword(data) {
    let hasError = [];
    if (!data.email || data.email.length < 6) {
        hasError.push("Fill in your email");
    }
    if (hasError && hasError.length > 0) {
        return new Promise((resolve, reject) => {
          reject({
            data: {
                error: {
                status: 400,
                message: hasError,
                data: data
              }
            }
          });
        });
    }

    return axios
      .post(API_URL + '/forgotPassword', {
        email: data.email
      }, {withCredentials: true})
      .then(response => {
        if (response.data.status == 200 && response.data) {
          return {
            success: true,
            status: 200,
            message: 'Password reset email sent!'
          }
        }
        return response.data || response;
      });
  }

  changePassword(data) {
    let hasError = [];
    if (!data.username || data.username.length < 6) {
        hasError.push("Fill in your email");
    }
    if (!data.password || data.password.length < 6) {
        hasError.push("Fill in your password at least 6 characters long");
    }
    if (!data.confirmPassword || data.confirmPassword.length < 6) {
        hasError.push("Repeat your password");
    }
    if (data.confirmPassword !== data.password) {
        hasError.push("Passwords do not match!");
    }
    if (hasError && hasError.length > 0) {
        return new Promise((resolve, reject) => {
          reject({
            data: {
                error: {
                status: 400,
                message: hasError,
                data: data
              }
            }
          });
        });
    }

    return axios
      .post(API_URL + '/changePassword', {
        username: data.username,
        password: data.password,
        confirmPassword: data.confirmPassword,
        code: data.forgotToken
      }, {withCredentials: true})
      .then(response => {
        if (response.data.status == 200 && response.data) {
          return {
            success: true,
            status: 200,
            message: 'Password updated!'
          }
        }
        return response.data || response;
      });
  }

  logout() {
    localStorage.removeItem('user');
  }

  me(user) {
    if (user && Date.now() - (user.lastRequest || 0) <= 6000 ) {
      const isStillActive = this.checkLocal();
      if (isStillActive) {
        if (!user.lastLogin) {
          user.lastLogin = Date.now();
        }
        return Promise.resolve(user);
      } else {
        return Promise.reject(user);
      }
    }
    return axios
      .get(API_URL + '/me', {withCredentials: true})
      .then(response => {
        if (response.data.status == 200 && response.data.data) {
          let userData = response.data.data;
          userData.location = userData.location || localStorage.getItem('location');
          userData.lastRequest = Date.now();
          if (!user.lastLogin) {
            user.lastLogin = Date.now();
          }
          if (!userData.expiry) {
            const Tomorrow = new Date();
            Tomorrow.setHours(Tomorrow.getHours() + 24);
            userData.expiry = {
              "date": Tomorrow.toISOString(),
              "timezone_type": 1,
              "timezone": "UTC"
            };
          }
          try {
            localStorage.setItem('user', JSON.stringify(userData));
            if(userData.location) localStorage.setItem('location', JSON.stringify(userData.location.replace(/[\\"]/g,'')));

          } catch(err) {
            console.error(err);
          }
          setCorrectLocationParams(userData.location);
        }

        return response.response || response.data;
      });
  }

  verify(data) {
    let hasError = [];
    if (!data.code || data.code.length < 6) {
        hasError.push("Invalid email!");
    }
    if (!data.verifyToken) {
        hasError.push("Invalid URL!");
    }
    if (hasError.length > 0) {
        return new Promise((resolve, reject) => {
          reject({
            data: {
                error: {
                status: 400,
                message: hasError,
                data: data
              }
            }
          });
        });
    }
    data.email = window.atob(data.code);
    return new Promise((resolve, reject) => {
      axios.post(API_URL + '/verify', data, {withCredentials: true})
        .then(response => {
          if (response.data.status == 200) {
            return resolve(response.data || response);
          }
          return reject(response.data?.data || response);
 
        }).catch(response => {reject(response.data || response)});
      });
  }

  signup(data) {
    let hasError = [];
    if (!data.firstName || !data.lastName) {
        hasError.push("Fill in your names");
    }
    if (data.email.length < 6) {
        hasError.push("Fill in your email correctly");
    }
    if (data.email.indexOf('aderize.com') < 1) {
        hasError.push("Only aderize.com emails allowed!");
    }
    if (data.password.length < 6) {
        hasError.push("Fill in your password");
    }
    if (data.confirmPassword.length < 6 || data.password !== data.confirmPassword) {
        hasError.push("Fill in both your passwords the same way");
    }
    if (!data.termsChecked) {
        hasError.push("You have to accept the terms!");
    }
    if (hasError.length > 0) {
        return new Promise((resolve, reject) => {
          reject({
            data: {
                error: {
                status: 400,
                message: hasError,
                data: data
              }
            }
          });
        });
    }
    return new Promise((resolve, reject) => {
      axios.post(API_URL + '/signup', data, {withCredentials: true})
        .then(response => {
          if (response.data.status == 200 && response.data.user) {
            let userData = response.data;
            localStorage.setItem('user', JSON.stringify(userData));
            return resolve(response.response || response);
          }
          return reject(response.response || response);
 
        }).catch(response => {reject(response.response?.data || response)});
      });
  }

  checkLocal(usr) {
    let user = null;
    try {
      user = usr || JSON.parse(localStorage.getItem('user'));
      if (user && user.expiry) {
        const expiryTimestamp = user.expiry.timezone_type !== 3 ? Date.parse(user.expiry.date) : Date.parse(user.expiry.date + ' ' + user.expiry.timezone);
        const curTimestamp = Date.now();
        
        return expiryTimestamp > curTimestamp;
      }
      return false;
    } catch(err) {
      localStorage.removeItem('user');
      console.error(err);
      return false;
    }
  }
}

export default new AuthService();
