const isImage = (filetype) => filetype.includes('image');

const isVideo = (filetype) => filetype.includes('video');

const analyzeFile = (file) => {
    return new Promise((resolve) => {
        const output = {};
        if (isImage(file.type)) {
            const img = new Image();
            img.onload = function() {
                window.URL.revokeObjectURL(img.src);
                output.width = Math.max(img.naturalWidth,img.width);
                output.height = Math.max(img.naturalHeight,img.height);
                resolve(output);
            }
            img.src = URL.createObjectURL(file);
    
        } else if(isVideo(file.type)) {
            const video = document.createElement('video');
            video.preload = 'metadata';
            video.onloadedmetadata = function() {
                window.URL.revokeObjectURL(video.src);
                output.duration = video.duration;
                output.width = Math.max(video.videoWidth,video.width);
                output.height = Math.max(video.videoHeight,video.height);
                resolve(output);
            }
            video.src = URL.createObjectURL(file);
        } else {
            resolve(output);
        }

    })
}

export {analyzeFile};