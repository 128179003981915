<template>
    <div>
      <div class="col-md-12">
        <label for="tz-select" class="form-control-label"
          >Select timezone</label
        >
        <UIDropdown name="tz-select" id="tz-select" :modelValue="timezone" @input="handleTimezoneChange" :values="timezones" />
      </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { minimalTimezoneSet } from 'compact-timezone-list';
import UIDropdown from "@/components/UIDropdown.vue";

export default defineComponent({
    name: 'TimezonePickerComponent',
    components: {
        UIDropdown
    },
    data() {
        return {
            timezone: this.$store.state.activeReportingTimezone.label || this.$store.state.activeReportingTimezone,
            date: null,
            result: null,
            timezones: null,
            interval: null,
            defaultTZ: {
                label: "(GMT+00:00) UTC",
                offset: "+00:00",
                tzCode: "UTC"
            }

        }
    },
    mounted: function(){
        const timezoneOutput = [
            this.defaultTZ.label
        ];
        minimalTimezoneSet.forEach((tz) => timezoneOutput.push(tz.label));
        this.timezones = timezoneOutput
    },
    unmounted: function(){
        clearInterval(this.interval);
    },
    methods:{
        handleTimezoneChange(event){
            this.timezone = event;
            const selected = minimalTimezoneSet.filter((tz) => tz.label == event);
            this.$emit('timezone-selected', selected && selected[0] || this.defaultTZ);
        }
    },
    emits: ['timezone-selected']
});
</script>