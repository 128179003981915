<template>
  <div class="form-check">
    <input class="form-check-input" type="checkbox" :name="name" :id="id" :checked="checked"
        @input="$emit('update:modelValue', $event.target.checked)" />
    <label :for="id" class="custom-control-label">
      <slot />
    </label>
  </div>
</template>

<script>
export default {
  name: "UICheckbox",
  props: {
    name: String,
    id: String,
    checked: String,
  },
  computed: {
    value: {
      get() {
        return this.checked == 'on'
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  emits: ['update:modelValue'],
};
</script>
