<template>
    <div class="notification-item flex h-full items-center text-white text-sm px-4 py-4 mb-2" :class="notification.type ? 'bg-gradient-' + notification.type + '' : 'bg-gradient-info'" role="alert">
        <h4 class="font-bold mr-2" v-if="notification.title">{{notification.title}}</h4>
        
        <span>{{notification.text}}</span>

        <div class="close-btn">
            <div class="circular-progress" role="progressbar" @click="triggerClose(notification)">
                <div class="inner-circle">
                    <svg height="35" width="35">
                        <circle class="circle" cx="17.5" cy="17.5" r="15" fill-opacity="0" :style="loadingAnimation" />
                    </svg>
                </div>
                <button type="button" class="btn-close" >&times;</button>

            </div>
        </div>

    </div>
</template>

<script>
export default {
    props: ['notification'],
    data() {
        return {
            timer: null,
            progress:0,
            loadingAnimation:''
        }
    },
    mounted() {
        let timeout = this.notification.timeout != null ? this.notification.timeout : true
        

        if (timeout) {
            let delay = this.notification.delay || 6000
            this.loadingAnimation = 'animation:stroke-fill ' + delay/1000 + 's ease-in-out forwards';

            this.timer = setTimeout(function () {
                this.triggerClose(this.notification)
            }.bind(this), delay)
        }
    },
    methods: {
        triggerClose: function (notification) {
            clearTimeout(this.timer)
            this.$emit('close-notification', notification)
        }
    },
}
</script>