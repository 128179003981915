import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import { filemanager } from "./store/filemanager.module";
import Dashboard from "./dashboard";
import axios from 'axios'
import VueAxios from 'vue-axios'

import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'


const appInstance = createApp(App);
appInstance.use(store);
appInstance.use(router);
appInstance.use(Dashboard);
appInstance.use(VueAxios,axios);
if (process.env.NODE_ENV === 'production') {
  Bugsnag.start({
    apiKey: '9bc6f5abb416eb7657131c73395ad568',
    plugins: [new BugsnagPluginVue()]
  })
  const bugsnagVue = Bugsnag.getPlugin('vue')
  appInstance.use(bugsnagVue);
}


appInstance.mount("#app");

store.dispatch("auth/amILoggedIn").then(() => {
    store.registerModule('filemanager', filemanager);
  }).catch(() => {
    // Not logged in err
  })

store.watch(
    function (state) {
        return state;
    },

    function (state) {
      let store = {
          version: state.version,
          darkMode: state.darkMode,
          sidebarType: state.sidebarType,
          layout: state.layout,
          //activeReporting: state.activeReporting,
          activeReportingDates: state.activeReportingDates,
          activeReportingTimezone: state.activeReportingTimezone,
          campaignGrouping: state.campaignGrouping
      };
  
      localStorage.setItem('store', JSON.stringify(store));
    },
    {
      deep: true //add this if u need to watch object properties change etc.
    }
);