import { reactive } from 'vue';

/* const makeid = (length) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
} */

const defaultObjectStyles = {
  'text': {
    'overflow-wrap': 'break-word',
    'font-family': 'inherit',
    color: '#000',
    'font-weight': 500
  }
};

const defaultStyle = {
};

const emptyState = {
  loading: false,
  layers: [
    {
      id: 'artboard_1',
      type: 'artboard',
      name: 'Artboard 1',
      width: 300,
      height: 250,
      left: 50,
      top: 50,
      children: [],
      parent: null,
      style: {
        background: '#FFF',
      }
    }
  ],
  meta: {
    writing: false
  },
  assets: [],
  keyframes: [],
  history: [],
  selectedLayer: null,
  selectedArtboard: null,
  keysdown: [],
  shapeCounter: 0,
  groupCounter: 0,
  artboardCounter: 1,
  drawing: null
};

const findLayer = (id,layerList) => {
  if (!id || !layerList) return;
  let output = null;
  const searchable = layerList;
  for (let i = 0; i < searchable.length; i++) {
    const layer = searchable[i];
    if (layer.id === id) {
      output = layer;
    } else if(layer.children) {
      output = findLayer(id, layer.children);
    }
    if (output) break;
  }
  return output;
}

export const adbuilder = {
    namespaced: true,

    state: reactive(emptyState),
    mutations: {
        addKeyframe(state, keyframe) {
          state.keyframes.push(keyframe);
        },
        addToHistory(state, action) {
          state.history.push(action);
        },
        undo(state) {
          state.history.pop();
        },
        initElement(state, id, el) {
          for(let i = 0; i < state.layers.length; i++) {
            if (state.layers[i].id === id) {
              state.layers[i].el = el;
            }
          }
        },
        addLayer(state, layer) {
          layer.id = layer.type + '-' + Date.now();
          layer.style = Object.assign(layer.style || {}, defaultStyle, defaultObjectStyles[layer.type] || {}, layer.style || {});
          if (state.selectedArtboard !== null) {
            layer.parent = state.selectedArtboard.id;
            state.selectedArtboard.children.push(layer);
          } else {
            state.layers.push(layer);
          }
          state.selectedLayer = layer;
        },
        addAsset(state, asset) {
          asset.id = 'asset-'+Date.now();
          state.assets.push(asset);
        },
        moveLayer(state, layerData) {
          const layer = findLayer(layerData.id, state.layers);
          if (layer && layerData) {
            layer.top = layerData.top;
            layer.left = layerData.left;
            if(layerData.width) layer.width = layerData.width;
            if(layerData.height) layer.height = layerData.height;
          }
        },
        changeValue(state, layerData) {
          const layer = findLayer(layerData.id, state.layers);
          if (layer && layerData) {
            if (layer.type === 'text') {
              layer.content = layerData.content;
            }
          }
        }
    }
};
