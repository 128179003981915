<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          isBlur="blur  border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"
          v-bind:darkMode="true"
          isBtn="bg-gradient-success"
        />
      </div>
    </div>
  </div>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0">
              <div class="card card-plain">
                <div class="pb-0 card-header text-start">
                  <h4 class="font-weight-bolder">Sign In</h4>
                  <p class="mb-0">Enter your email and password to sign in</p>
                </div>
                <div class="card-body">
                  <div class="alert"
                     v-if="alert"
                     :class="{ 'alert-success': alertSuccess, 'alert-danger': !alertSuccess }">{{ alert }}</div>
                  <form @submit.prevent="loginWithPassword" role="form">
                    <div class="mb-3">
                      <UIInput type="email" placeholder="Email" name="email" size="lg" v-model="email" />
                    </div>
                    <div class="mb-3">
                      <UIInput type="password" placeholder="Password" name="password" size="lg" v-model="password" />
                    </div>
                    <UISwitch id="rememberMe" name="remember" v-model="remember">Remember me</UISwitch>

                    <div class="text-center">
                      <UIButton
                        class="mt-4"
                        variant="gradient"
                        color="success"
                        fullWidth
                        size="lg"
                      >Sign in</UIButton>
                    </div>
                  </form>
                </div>
                <div class="px-1 pt-0 text-center card-footer px-lg-2">
                  <p class="mx-auto mb-4 text-sm">
                    Don't have an account?

                    <router-link
                      :to="{ name: 'Signup' }"
                      class="text-success text-gradient font-weight-bold"
                    >
                      Sign up
                    </router-link>
                  </p>
                  <p class="mx-auto mb-4 text-sm">
                    Forgot your password?

                    <router-link
                      :to="{ name: 'Forgot your password' }"
                      class="text-success text-gradient font-weight-bold"
                    >
                      Reset your password
                    </router-link>
                  </p>
                </div>
              </div>
            </div>
            <div
              class="top-0 my-auto text-center col-6 d-lg-flex d-none h-100 pe-0 position-absolute end-0 justify-content-center flex-column"
            >
              <div
                class="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden"
                :style="{backgroundImage: 'url(' + require('@/assets/img/signin.jpg') + ')',
                backgroundSize: 'cover',
                backgroundPosition: 'center right'}"
              >
                <span class="mask bg-gradient-success opacity-6"></span>
                <h4
                  class="mt-5 text-white font-weight-bolder position-relative"
                >"Attention is the new currency"</h4>
                <p
                  class="text-white position-relative"
                >Let's focus people!.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import UIInput from "@/components/UIInput.vue";
import UISwitch from "@/components/UISwitch.vue";
import UIButton from "@/components/UIButton.vue";
import { filemanager } from "@/store/filemanager.module";
const body = document.getElementsByTagName("body")[0];

function getData(form) {
  const formData = new FormData(form);

  const output = Object.fromEntries(formData);

  return output;
}

export default {
  name: "signin",
  components: {
    Navbar,
    UIInput,
    UISwitch,
    UIButton,
  },
  data() {
    return {
      email: "",
      password: "",
      alert: "",
      alertSuccess: true,
      remember: false,
      loading: false,
      verifyToken: this.$route.query.verifyCode
    };
  },
  created() {
    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
    if (this.verifyToken) {
      const that = this;
      this.$store.dispatch("auth/verify",{
        verifyToken: this.verifyToken,
        code: this.$route.query.code
      }).then(() => {
        that.loading = false;
        that.alert = 'Email verified! Sign in below';
        that.alertSuccess = true;
      }).catch((err) => {
        const errorMessage = ( err.data?.error || 'Verification failed!');
        that.alert = errorMessage instanceof Array ? errorMessage.join('\n') : errorMessage;
        that.alertSuccess = false;
        that.loading = false;
      })

    }

    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    body.classList.add("bg-gray-100");
  },
  methods: {
    loginWithPassword: function(e) {
      const that = this;
      that.alert = '';
      that.loading = true;
      const formData = getData(e.target);
      const afterLogin = localStorage.getItem('afterLogin');
      that.$store.dispatch("auth/login",{
        method: "password",
        username: formData.email,
        password: formData.password,
        remember: formData.remember
      }).then(() => {
        that.$store.registerModule('filemanager', filemanager);
        that.loading = false;
        that.$router.push(afterLogin || '/dashboard');
        localStorage.removeItem('afterLogin');
        that.alertSuccess = true;
      }).catch((err) => {
        const errorMessage = (err.data?.error?.message || err.data?.message || err.data?.error || 'Sign up failed!');
        that.alert = errorMessage instanceof Array ? errorMessage.join('\n') : errorMessage;
        that.loading = false;
        that.alertSuccess = false;
      })
    }
  }
};
</script>
