import { reactive, watch, computed } from 'vue';
import AWS from 'aws-sdk';

const storedData = JSON.parse( '{}');

const hashData = new URLSearchParams(window.location.hash.slice(1));
const locationSpecific = {
  us: {
    region: 'us-east-1',
    currentBucket: 'storage-us.aderize.com',
    rememberedBuckets: ['storage-us.aderize.com'],
    identityPoolId: 'us-east-1:acdf6d44-a4d9-4c81-86e9-b7d9cbb55d21',
  },
  apac: {
    region: 'ap-southeast-1',
    currentBucket: 'storage.aderize.com',
    rememberedBuckets: ['storage.aderize.com'],
    identityPoolId: 'ap-southeast-1:2d8171cb-129c-41e7-9d0a-d7ae4b0a8cd2',
    
  }
};

if (process.env.NODE_ENV === 'development') {
  locationSpecific.all = {
    region: 'eu-north-1',
    currentBucket: 'storage-test.aderize.com',
    rememberedBuckets: ['storage-test.aderize.com'],
    identityPoolId: 'eu-north-1:23833f50-4d6f-44b0-b03a-ae558ce0cc0a',

  }
}


const store = reactive(Object.assign({
    region: 'ap-southeast-1',

    delimiter: '/',
    currentBucket: 'storage.aderize.com',
    rememberedBuckets: ['storage.aderize.com'],
    overrideBucketName: 'storage.aderize.com',
    currentDirectory: hashData.get('path'),

    mrap: 'storage-aderize-com-multiregion-ap',

    awsAccountId: '209570800564',
    cognitoPoolId: null,
    applicationClientId: null,
    applicationLoginUrl: null,
    identityPoolId: 'ap-southeast-1:2d8171cb-129c-41e7-9d0a-d7ae4b0a8cd2',
    userRoleId: null,

    objects: [],

    tokens: true,

    allowBucketSelection : false,

    loggedOut: false,
    autoLoginIn: false,
    sharedSettings: {},
    
    currentLocation: null
  }, storedData, {
    initialized: false,
    globalLoader: true,
    loggedOut: false,
    showBucketSelector: false,
    showSettings: false,
    showAddFolder: false,
    showTrash: false,
    showUploads: false,
    deletedObjects: {},
    copyObjects: [],
    moveObjects: []
}));

export const setCorrectLocationParams = function(location) {
  if (location && (locationSpecific.all || locationSpecific[location])) {
    const locSpecific = locationSpecific.all || locationSpecific[location];
    Object.assign(store,locSpecific);
  }
}

export const getBuckets = function() {
  return store.rememberedBuckets.concat(store.sharedSettings?.buckets || [])
  .map(bucket => {
    if (typeof bucket === 'object') {
      return bucket;
    }

    return { bucket };
  }).sort((a, b) => a.bucket.localeCompare(b.bucket));
}

export const filemanager = {
  state: store,
  actions: {
    setCorrectLocationParams: setCorrectLocationParams
  }
};

watch(store, () => {

  if (store.autoUpdate) {
    return store.autoUpdate = false;
  }

  localStorage.setItem('s3console', JSON.stringify(store));
  AWS.config.update({ region: store.region, identityPoolId: store.identityPoolId });
});

const currentBucket = computed(() => store.currentBucket);
watch(currentBucket, () => {
  store.deletedObjects = {};
});

export const s3StorageClasses = {
  STANDARD: 'Standard',
  STANDARD_IA: 'Standard IA',
  ONEZONE_IA: 'One Zone-IA',
  REDUCED_REDUNDANCY: 'Reduced Redundancy',
  GLACIER: 'Glacier',
  INTELLIGENT_TIERING: 'Intelligent Tiering',
  DEEP_ARCHIVE: 'Deep Archive'
};
