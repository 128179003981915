import { reactive } from 'vue';
import { mapGetters } from 'vuex'
import {CampaignsService, CampaignsServicePublicFunctions} from '@/services/campaigns.service';

/* const makeid = (length) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
} */

const USE_LOCAL = true;

const storeDefault = {
  loading:false,
  pullingData:false,
  updating:false,
  newCreatives: {},
  listHasMore: true,
  listPage: null,
};

const store = reactive(Object.assign({campaigns: []},(USE_LOCAL ? (JSON.parse(localStorage.getItem('campaigns'))||{}) : {}), storeDefault));



export const campaigns = {
  namespaced: true,
  state: store,
  mounted() {

  },
  /* watch: {
    "saveState": () => {
      console.log("CAMPAIGNS CHANGED",this.campaigns);
      window.localStorage.setItem('campaigns', JSON.stringify(this.campaigns));
    },
  }, */
  computed: {
    ...mapGetters('store',[
      ...CampaignsServicePublicFunctions
    ])
  },
  getters: {
    ...CampaignsService
  }

};
