<template>
  <notifications></notifications>
  <div
    v-show="this.$store.state.layout === 'landing'"
    class="landing-bg h-100 bg-gradient-primary position-fixed w-100"
  ></div>
  <sidenav
    :custom_class="this.$store.state.mcolor"
    :class="[
      this.$store.state.isTransparent,
      'fixed-start'
    ]"
    v-if="this.$store.state.showSidenav"
  />
  <main
    class="main-content position-relative max-height-vh-100 h-100 border-radius-lg"
  >
    <!-- nav -->
    <navbar
      :class="[navClasses]"
      :textWhite="
        this.$store.state.isAbsolute ? 'text-white opacity-8' : 'text-white'
      "
      :minNav="navbarMinimize"
      v-if="this.$store.state.showNavbar"
    />
    <router-view />
    <app-footer v-show="this.$store.state.showFooter" />
   
  </main>
</template>
<script>
import Notifications from '@/components/notifications/NotificationList';

import Sidenav from "@/components/Sidenav";
import Navbar from "@/examples/Navbars/Navbar.vue";
import AppFooter from "@/examples/Footer.vue";
import { mapMutations } from "vuex";

export default {
  name: "App",
  components: {
    Sidenav,
    Navbar,
    AppFooter,
    Notifications
  },
  methods: {
    ...mapMutations([ "navbarMinimize"])
  },
  computed: {
    navClasses() {
      return {
        "position-sticky bg-white left-auto top-2 z-index-sticky":
          this.$store.state.isNavFixed && !this.$store.state.darkMode,
        "position-sticky bg-default left-auto top-2 z-index-sticky":
          this.$store.state.isNavFixed && this.$store.state.darkMode,
        "position-absolute px-4 mx-0 w-100 z-index-2": this.$store.state
          .isAbsolute,
        "px-0 mx-4": !this.$store.state.isAbsolute
      };
    }
  },
  mounted() {
    const userAuth = this.$store.state.auth;
    if (userAuth.status.loggedIn && (!userAuth.lastLogin || userAuth.lastLogin < Date.now() - 100000) ) {
      this.$store
        .commit('notifications/ADD_NOTIFICATION', {
            title: 'Welcome back!',
            text: 'There has been a new release of bug fixes and some extra features.',
            type: 'success',
            timeout: true,
            delay: 10000
        });    
      }
  },
  beforeMount() {
    this.$store.state.isTransparent = "bg-transparent";
  },
  beforeCreate() {
		this.$store.commit('initialiseStore');
	}
};
</script>
