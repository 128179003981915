import AuthService from '../services/auth.service';

let user = null;
    
try {
    user = JSON.parse(localStorage.getItem('user'));
} catch(err) {
    localStorage.removeItem('user');
    console.error(err);
}
const loggedIn = AuthService.checkLocal();
const initialState = user
    ? { status: { loggedIn: loggedIn }, user }
    : { status: { loggedIn: null }, user: null };


export const auth = {
    namespaced: true,
    state: initialState,
    actions: {
        login({ commit }, user) {
            return AuthService.login(user).then(
                user => {
                    commit('loginSuccess', user);
                    return Promise.resolve(user);
                },
                error => {
                    commit('loginFailure');
                    return Promise.reject(error.response);
                }
            );
        },
        changePassword({ commit }, data) {
            return AuthService.changePassword(data).then(
                data => {
                    commit('passwordChangeSuccess', user);
                    return Promise.resolve(data);
                },
                error => {
                    commit('passwordChangeFailure');
                    return Promise.reject(error);
                }
            );
        },
        forgotPassword({ commit }, data) {
            return AuthService.forgotPassword(data).then(
                data => {
                    commit('passwordChangeSuccess', user);
                    return Promise.resolve(data);
                },
                error => {
                    commit('passwordChangeFailure');
                    return Promise.reject(error);
                }
            );
        },
        logout({ commit }) {
            AuthService.logout();
            commit('logout');
        },
        signup({ commit }, user) {
            return AuthService.signup(user).then(
                response => {
                    commit('registerSuccess');
                    return Promise.resolve(response.data);
                },
                error => {
                    commit('registerFailure');
                    return Promise.reject(error);
                }
            );
        },
        verify({ commit }, user) {
            return AuthService.verify(user).then(
                response => {
                    commit('verifySuccess');
                    return Promise.resolve(response.data);
                },
                error => {
                    commit('verifyFailure');
                    return Promise.reject(error.response);
                }
            );
        },
        me({ commit }) {
            const user = JSON.parse(localStorage.getItem('user'));

            return AuthService.me(user).then(
                response => {
                    commit('authSuccess');
                    return Promise.resolve(response.data);
                },
                error => {
                    commit('authFailure');
                    return Promise.reject(error);
                }
            );
        },
        amILoggedIn({commit}) {
            const loggedIn = AuthService.checkLocal();
            if(loggedIn) {
                commit('authSuccess');
                return Promise.resolve(loggedIn);
            }
            else {
                commit('authFailure');
                return Promise.reject(loggedIn);
            } 
        }
    },
    mutations: {
        loginSuccess(state, user) {
            state.status.loggedIn = true;
            state.user = user;
        },
        loginFailure(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        authSuccess(state) {
            state.status.loggedIn = true;
        },
        authFailure(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        logout(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        registerSuccess(state) {
            state.status.loggedIn = false;
        },
        registerFailure(state) {
            state.status.loggedIn = false;
        },
        verifySuccess(state) {
            state.status.loggedIn = false;
        },
        verifyFailure(state) {
            state.status.loggedIn = false;
        },
        passwordChangeSuccess(state) {
            state.status.loggedIn = false;
        },
        passwordChangeFailure(state) {
            state.status.loggedIn = false;
        }
    }
};