import { createRouter, createWebHistory } from "vue-router";

//import Dashboard from "@/views/Dashboard.vue";
import Tables from "@/views/Tables.vue";
import Billing from "@/views/Billing.vue";
import Profile from "@/views/Profile.vue";
import Signup from "@/views/Signup.vue";
import Signin from "@/views/Signin.vue";
import Ping from "@/views/Ping.vue";
import ForgotPass from "@/views/ForgotPass.vue";

import store from "../store";

const authNotRequired = [
  '/signin',
  '/signup',
  '/ping',
  '/forgotpassword',
  '/',
  '',
  '/share'
];

const authRequiredRoutes = store.state.auth.status.loggedIn === true || store ? [
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import('@/views/Dashboard.vue'),
  },
  {
    path: "/tables",
    name: "Tables",
    component: Tables,
  },
  {
    path: "/billing",
    name: "Billing",
    component: Billing,
  },
  {
    path: "/filemanager",
    name: "File manager",
    component: () => import('@/views/filemanager/Filemanager.vue'),
  },
  {
    path: "/builder",
    name: "Ad builder",
    component: () => import('@/views/ad-builder/Home.vue'),
    children: [
      {
        path: "",
        name: "Creative builder",
        component: () => import('@/views/ad-builder/AdBuilder.vue'),
      },
    ]
  },
  {
    path: "/campaigns",
    name: "Campaigns",
    component: () => import('@/views/Campaigns.vue'),
    children: [
      {
        path: "",
        name: "Campaign list",
        component: () => import('@/views/ad-server/CampaignsList.vue'),
      },
      {
        path: ":campaignID",
        name: "Campaign",
        component: () => import('@/views/ad-server/CampaignContainer.vue'),
        children: [
          {
            path: "",
            name: "Campaign view",
            component: () => import('@/views/ad-server/CampaignView.vue')
          },
          {
            path: "reporting",
            name: "Campaign reporting",
            component: () => import('@/views/ad-server/reporting/CampaignView.vue')
          },
          {
            path: "reporting/creative/:creativeID",
            name: "Creative reporting",
            component: () => import('@/views/ad-server/reporting/CampaignView.vue')
          },
          {
            path: "creative/:creativeID",
            name: "Creative view",
            component: () => import('@/views/ad-server/CampaignView.vue') // it's broken, but anyways should have the creative open in the same view ;) so use the component in accordion
          }
        ]
      }
    ]
  },
  {
    path: "/admin",
    name: "Admin",
    component: () => import('@/views/admin/Admin.vue'),
    children: [
      { 
        path: '',
        name: 'User list',
        component: () => import('@/views/admin/List.vue'),
      },
      {
        path: "invite",
        name: "Invite new users",
        component: () => import('@/views/admin/Invite.vue'),
      },

    ]
  },
] : [];

const authNotRequiredRoutes = [
  {
    path: "/",
    name: "/"
  },
  {
    path: "/ping",
    name: "Ping",
    component: Ping,
  },
  {
    path: "/signin",
    name: "Signin",
    component: Signin,
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/forgotpassword",
    name: "Forgot your password",
    component: ForgotPass,
  },
  {
    path: "/forgotpassword",
    name: "Forgot your password",
    component: ForgotPass,
  },
  {
    path: "/share",
    name: "Share campaigns",
    title: "Campaign reporting",
    component: () => import('@/views/Campaigns.vue'),
    children: [
      {
        path: ":shareToken",
        name: "Share campaign",
        component: () => import('@/views/ad-server/CampaignContainer.vue'),
        children: [
          {
            path: "",
            name: "Share campaign reporting",
            component: () => import('@/views/ad-server/reporting/CampaignView.vue')
          },
          {
            path: "creative/:creativeID",
            name: "Share creative reporting",
            component: () => import('@/views/ad-server/reporting/CampaignView.vue')
          }
        ]
      }
    ]
  }
];

const routes = [...authNotRequiredRoutes, ...authRequiredRoutes];

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
  linkActiveClass: "active",
  mode: 'history',
  hashbag: true,
  scrollBehavior(to, from, savedPosition) {
    // This doesn't work with tables?
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
        left:0,
        top:20
      }
    } else if (to.params && (to.params.selector || to.params.creativeID)) {
      return {
        el: '#' + (to.params.selector || 'creative-' + to.params.creativeID),
        behavior: 'smooth',
        left:0,
        top:20
      }
    } else if (savedPosition) {
      return savedPosition
    } else if (
      to.path == from.path || 
      to.matched[0] && from.matched[0] && to.matched[0].path == from.matched[0].path
    ) {
      return {};
    } else {
      return { }
    }
  }
});

router.beforeEach((to, from, next) => {
  let authRequired = true;
  authNotRequired.forEach((path) => {
    if (to.path.startsWith(path)) authRequired = false;
  })
  console.log("To",to,authRequired);
  if(authRequired) {
    if (store.state.auth.status.loggedIn === true) {
      next();
    } else {
      if(authRequired) localStorage.setItem('afterLogin',to.path);
      next('/signin');
    }
  } else if(to.path === '/' || to.path === '') {
    if (store.state.auth.status.loggedIn === true) {
      next('/dashboard');
    } else {
      if(authRequired) localStorage.setItem('afterLogin',to.path);
      next('/signin');
    }

  } else {
    if (to.path.startsWith('/share')) {
      store.state.showPrimaryNav = false;
      store.state.showHeaderInfo = false;
      store.state.showFooter = false;
    }
    next();
  }
  store.dispatch('auth/me').then(() => {
    if (authRequired && !store.state.auth.status.loggedIn) {
      router.push('/signin');
    }
  }).catch(() => {
    if (authRequired) {
      router.push('/signin');
    }

  });
})


export default router;
