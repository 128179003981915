import { createStore } from "vuex";
import { auth } from "./auth.module";
import { campaigns } from "./campaigns.module";
import { adbuilder } from "./adbuilder.module";
import { notifications } from "./notifications.module";
import packageInfo from '/package.json';
import { activateDarkMode } from "@/assets/js/dark-mode";

const stateDefault = {
  hideConfigButton: true,
  isPinned: true,
  showConfig: false,
  sidebarType: "bg-white",
  isRTL: false,
  mcolor: "",
  darkMode: false,
  isNavFixed: false,
  isAbsolute: false,
  showNavs: true,
  showSidenav: true,
  showNavbar: true,
  showFooter: true,
  showPrimaryNav:true,
  showHeaderInfo:true,
  showMain: true,
  layout: "default",
  api_url: process.env.VUE_APP_API_URL,
  activeReporting: {},
  activeReportingDates: [],
  activeReportingTimezone: {
    label: "(GMT+00:00) UTC",
    offset: "+00:00",
    tzCode: "UTC"
  },
  version: packageInfo.version,
  campaignGrouping: 'list'
};

let savedState = stateDefault;
let store = JSON.parse(localStorage.getItem('store'))||{};
if(store.version == packageInfo.version) {
  savedState = Object.assign(savedState, store);
} else {
  savedState.version = packageInfo.version;
}


export default createStore({
  state: savedState,
  modules: {
    auth,
    campaigns,
    adbuilder,
    notifications
  },
  mutations: {
    initialiseStore(state) {
			if (state.darkMode) {
        activateDarkMode();
      }
		},
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },
    navbarMinimize(state) {
      const sidenav_show = document.querySelector(".g-sidenav-show");

      if (sidenav_show.classList.contains("g-sidenav-hidden")) {
        sidenav_show.classList.remove("g-sidenav-hidden");
        sidenav_show.classList.add("g-sidenav-pinned");
        state.isPinned = true;
      } else {
        sidenav_show.classList.add("g-sidenav-hidden");
        sidenav_show.classList.remove("g-sidenav-pinned");
        state.isPinned = false;
      }
    },
    sidebarType(state, payload) {
      state.sidebarType = payload;
    },
    navbarFixed(state) {
      if (state.isNavFixed === false) {
        state.isNavFixed = true;
      } else {
        state.isNavFixed = false;
      }
    },
  },
  actions: {
    toggleSidebarColor({ commit }, payload) {
      commit("sidebarType", payload);
    }
  },
  getters: {}
});
